import "./src/styles/index.css"
import React from 'react';
import FormContextProvider from './src/components/FormContext';

export const wrapRootElement = ({ element }) => {
  return <FormContextProvider>{element}</FormContextProvider>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
}
