import React, {createContext, useMemo, useState} from "react";

export const FormContext = createContext({
  form: {
    name: '',
    email: '',
    phone: '',
    gdprConfirmed: false,
    files: []
  },
  setForm: () => {}
})

const FormContextProvider = ({children}) => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    gdprConfirmed: false,
    files: []
  });

  const value = useMemo(
    () => ({ form, setForm }),
    [form]
  );

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  )
}



export default FormContextProvider
